import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import {
  faTrash,
  faStar,
  faCrown,
  faCalendarAlt,
  faTimesCircle,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../assets/styling/singleUser.css";
import Navbar from "../../components/layout/navbar";
import Modal from "react-modal";
import ENV from "../../utils/env";

function SingleActivity(props) {
  const { id } = useParams();
  const [activity, setActivity] = useState({});
  const [editButton, setEditButton] = useState(true);
  const [activityStatus, setActivityStatus] = useState("");
  const [title, setTitle] = useState("");
  const [address, setAddress] = useState("");
  const [gender, setGender] = useState("");
  const [description, setDescription] = useState("");
  const [cost, setCost] = useState("");
  const [meetingPointInfo, setMeetingPointInfo] = useState(0);
  const [participantsNumber, setParticipantsNumber] = useState(0);
  const [ageFrom, setAgeFrom] = useState(0);
  const [ageTo, setAgeTo] = useState(0);
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(0);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    axios
      .get(
        ENV.api + `admins/activity/${id}`,
        {
          headers: {
            Authorization: localStorage.getItem("jwtToken"),
          },
          mode: "cors",
        },
        setIsLoading(true)
      )
      .then((res) => {
        setActivity(res.data);
        setTitle(res.data.title);
        setAddress(res.data.address);
        setAgeFrom(res.data.ageFrom);
        setAgeTo(res.data.ageTo);
        setDescription(res.data.description);
        setGender(res.data.gender);
        setMeetingPointInfo(res.data.meetingPointInfo);
        setActivityStatus(res.data.activityStatus);
        setCost(res.data.cost);
        setParticipantsNumber(res.data.participantsNumber);
        setFrom(res.data.from.getTime());
        setTo(res.data.to.getTime());
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }, [id]);
  const onChangeHandler = (e) => {
    if (e.target.name === "title") {
      setTitle(e.target.value);
    }
    if (e.target.name === "address") {
      setAddress(e.target.value);
    }
    if (e.target.name === "activityStatus") {
      setActivityStatus(e.target.value);
    }
    if (e.target.name === "description") {
      setDescription(e.target.value);
    }
    if (e.target.name === "ageFrom") {
      setAgeFrom(e.target.value);
    }
    if (e.target.name === "ageTo") {
      setAgeTo(e.target.value);
    }
    if (e.target.name === "gender") {
      setGender(e.target.value);
    }
    if (e.target.name === "cost") {
      setCost(e.target.value);
    }
    if (e.target.name === "participantsNumber") {
      setParticipantsNumber(e.target.value);
    }
    if (e.target.name === "meetingPointInfo") {
      setMeetingPointInfo(e.target.value);
    }
  };
  const onSubmitChangesHandler = () => {
    const data = {
      title,
      description,
      ageFrom,
      ageTo,
      meetingPointInfo,
      participantsNumber,
      cost,
      gender,
      address,
      activityStatus,
      from,
      to,
    };
    axios
      .put(
        ENV.api + `admins/editActivity/${id}`,
        data,
        {
          headers: {
            Authorization: localStorage.getItem("jwtToken"),
          },
          mode: "cors",
        },
        setIsLoading(true)
      )
      .then((res) => {
        setIsLoading(false);
        window.location.reload(true);
      })
      .catch((err) => {
        setErrors(err.response.data);
        setIsLoading(false);
      });
  };
  const onDeleteActivityHandler = () => {
    axios
      .delete(ENV.api + `admins/activity/${id}`, {
        headers: {
          Authorization: localStorage.getItem("jwtToken"),
        },
        mode: "cors",
      })
      .then((res) => {
        props.history.push("/activities");
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  };
  const dateHandler = (e) => {
    if (e.target.name === "from") {
      let date = e.target.value.split("-");
      const from = new Date(
        Number(date[0]),
        Number(date[1]) - 1,
        Number(date[2])
      );
      setFrom(from.getTime());
    }
    if (e.target.name === "to") {
      let date = e.target.value.split("-");
      const to = new Date(
        Number(date[0]),
        Number(date[1]) - 1,
        Number(date[2])
      );
      setTo(to.getTime());
    }
  };
  Modal.setAppElement("#root");
  if (isLoading) {
    return <div className="loader">Loading...</div>;
  }
  if (Object.keys(activity).length <= 1 && !isLoading) {
    return <p>No activity found with that ID</p>;
  } else {
    return (
      <div>
        <Navbar />
        <Modal
          isOpen={isOpen}
          onRequestClose={() => setIsOpen(false)}
          style={{
            overlay: { backgroundColor: "rgba(228,233,220,0.7)" },
            content: {
              top: "30%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
            },
          }}
          shouldCloseOnOverlayClick={false}
        >
          <h2 style={{ textAlign: "center" }}>Delete Activity?</h2>
          <p>
            Are you sure you want to delete '{activity.title}'?{" "}
            <b style={{ color: "red" }}>This action is irreversible.</b>{" "}
          </p>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <button className="btn-no" onClick={() => setIsOpen(false)}>
              No{" "}
            </button>
            <button className="btn-delete" onClick={onDeleteActivityHandler}>
              Yes{" "}
            </button>
          </div>
        </Modal>
        <div className="container">
          <div className="card-profile-organizer">
            <div>
              <Link
                to={`/users/${activity.organizer._id}`}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <img
                  alt="_"
                  className="profile-image-organizer"
                  src={
                    activity.organizer.profileImage
                  }
                />
              </Link>
            </div>
            <div className="organizer-details">
              <h3>Activity organizer</h3>
              <h1 className="profile-name">{activity.organizer.name}</h1>
              <h2>
                {" "}
                {activity.organizer.crowns}{" "}
                <FontAwesomeIcon color="gold" icon={faCrown}></FontAwesomeIcon>{" "}
              </h2>
            </div>
          </div>
          <hr />
          <div className="user-active">
            <h1>
              <FontAwesomeIcon
                icon={faCalendarAlt}
                className="user-active-icon"
              />
              Created at <b>{new Date(activity.createdAt).toDateString()}</b>{" "}
            </h1>
          </div>
          <div className="info-form">
            <div>
              <h4>Title</h4>
              <input
                className="form-info-input"
                type="text"
                name="title"
                value={editButton ? activity.title : title}
                disabled={editButton}
                onChange={onChangeHandler}
              />
            </div>
            <div>
              <h4>Description</h4>
              <input
                className="form-info-input"
                type="text"
                name="description"
                value={editButton ? activity.description : description}
                disabled={editButton}
                onChange={onChangeHandler}
              />
            </div>
            <div>
              <h4>Address</h4>
              <input
                className="form-info-input"
                type="text"
                name="address"
                value={editButton ? activity.address : address}
                disabled={editButton}
                onChange={onChangeHandler}
              />
            </div>

            <div>
              <h4>Age From</h4>
              <input
                className="form-info-input"
                type="text"
                name="ageFrom"
                value={editButton ? activity.ageFrom : ageFrom}
                onChange={onChangeHandler}
                disabled={editButton}
              />
            </div>
            <div>
              <h4>Age To</h4>
              <input
                className="form-info-input"
                type="text"
                name="ageTo"
                value={editButton ? activity.ageTo : ageTo}
                onChange={onChangeHandler}
                disabled={editButton}
              />
            </div>

            <div>
              <h4>Meeting point info</h4>
              <input
                className="form-info-input"
                type="text"
                name="meetingPointInfo"
                value={
                  editButton ? activity.meetingPointInfo : meetingPointInfo
                }
                onChange={onChangeHandler}
                disabled={editButton}
              />
            </div>
            <div>
              <h4>Cost</h4>
              <input
                className="form-info-input"
                type="text"
                name="cost"
                value={editButton ? activity.cost : cost}
                onChange={onChangeHandler}
                disabled={editButton}
              />
            </div>
            <div className="date-picker">
              <label htmlFor="from">
                From{" "}
                {new Date(activity.from).getFullYear() +
                  "-" +
                  (new Date(activity.from).getMonth() + 1) +
                  "-" +
                  new Date(activity.from).getDate()}
              </label>
              <br />
              <input
                className="date-picker2 "
                disabled={editButton}
                type="date"
                id="dateFrom"
                name="from"
                onChange={dateHandler}
              />
            </div>
            <div className="date-picker">
              <label htmlFor="to">
                To{" "}
                {new Date(activity.to).getFullYear() +
                  "-" +
                  (new Date(activity.to).getMonth() + 1) +
                  "-" +
                  new Date(activity.to).getDate()}
              </label>
              <br />
              <input
                className="date-picker2 "
                disabled={editButton}
                type="date"
                id="dateTo"
                name="to"
                onChange={dateHandler}
              />
            </div>
            <div>
              {" "}
              <h4>Gender</h4>
              <select
                className="gender"
                name="gender"
                value={editButton ? activity.gender : gender}
                disabled={editButton}
                onChange={onChangeHandler}
              >
                <option value="M">Male</option>
                <option value="F">Female</option>
                <option value="A">All</option>
              </select>
            </div>
            <div style={{ marginBottom: "20px" }}>
              {" "}
              <h4>Activity Status</h4>
              <select
                className="gender"
                name="activityStatus"
                value={editButton ? activity.activityStatus : activityStatus}
                disabled={editButton}
                onChange={onChangeHandler}
              >
                <option value="Open">Open</option>
                <option value="Full">Full</option>
                <option value="Closed">Closed</option>
                <option value="Cancelled">Cancelled</option>
              </select>
            </div>
          </div>
          <div className="btnDivs">
            <button
              className="btnEdit"
              onClick={() => setEditButton(!editButton)}
            >
              Edit Activity{" "}
              <FontAwesomeIcon
                color="white"
                className="btn-icon"
                icon={faCalendarAlt}
              />
            </button>
            <button className="btnRemove" onClick={() => setIsOpen(true)}>
              {" "}
              Delete Activity{" "}
              <FontAwesomeIcon
                color="white"
                className="btn-icon"
                icon={faCalendarAlt}
              />
            </button>
          </div>
          {!editButton && (
            <div className="btn-ceneterd">
              <button className="btn2" onClick={onSubmitChangesHandler}>
                Submit
                <FontAwesomeIcon
                  icon={faCheck}
                  color="white"
                  className="btn-icon"
                />
              </button>
            </div>
          )}

          <div className="user-review">
            {errors.title && (
              <h5 className="error-message">
                <FontAwesomeIcon icon={faTimesCircle} /> {" " + errors.title}{" "}
              </h5>
            )}
            {errors.description && (
              <h5 className="error-message">
                <FontAwesomeIcon icon={faTimesCircle} />{" "}
                {" " + errors.description + " "}{" "}
              </h5>
            )}
            {errors.ageFrom && (
              <h5 className="error-message">
                <FontAwesomeIcon icon={faTimesCircle} />{" "}
                {" " + errors.ageFrom + " "}{" "}
              </h5>
            )}
            {errors.ageTo && (
              <h5 className="error-message">
                <FontAwesomeIcon icon={faTimesCircle} />{" "}
                {" " + errors.ageTo + " "}{" "}
              </h5>
            )}
            {errors.meetingPointInfo && (
              <h5 className="error-message">
                {" "}
                <FontAwesomeIcon icon={faTimesCircle} />
                {" " + errors.meetingPointInfo + " "}{" "}
              </h5>
            )}
            {errors.gender && (
              <h5 className="error-message">
                <FontAwesomeIcon icon={faTimesCircle} />{" "}
                {" " + errors.gender + " "}{" "}
              </h5>
            )}
            {errors.participantsNumber && (
              <h5 className="error-message">
                <FontAwesomeIcon icon={faTimesCircle} />{" "}
                {" " + errors.participantsNumber + " "}{" "}
              </h5>
            )}
            {errors.address && (
              <h5 className="error-message">
                <FontAwesomeIcon icon={faTimesCircle} />{" "}
                {" " + errors.address + " "}{" "}
              </h5>
            )}
            {errors.cost && (
              <h5 className="error-message">
                <FontAwesomeIcon icon={faTimesCircle} />{" "}
                {" " + errors.cost + " "}{" "}
              </h5>
            )}
            {errors.from && (
              <h5 className="error-message">
                <FontAwesomeIcon icon={faTimesCircle} />{" "}
                {" " + errors.from + " "}{" "}
              </h5>
            )}
            {errors.to && (
              <h5 className="error-message">
                <FontAwesomeIcon icon={faTimesCircle} /> {" " + errors.to + " "}{" "}
              </h5>
            )}
          </div>
        </div>
        <hr />
        <div className="container">
          {activity.participants && activity.participants.length > 0 && (
            <div className="header-text">
              <h4>Activity's participants</h4>
            </div>
          )}
          <div className="user-review2">
            {activity.participants && (
              <ActivitiesParticipants participants={activity.participants} />
            )}
          </div>
        </div>
        <hr style={{ marginTop: "60px" }} />
        <div className="container">
          {activity.reviews.length > 0 && (
            <h4 style={{ marginTop: "50px" }}>Activity's Reviews</h4>
          )}
          <div className="review-info-item">
            {activity.reviews && <ActivityReviews reviews={activity.reviews} />}
          </div>
        </div>
      </div>
    );
  }
}
function ActivityReviews(props) {
  const { id } = useParams();
  const reviews = props.reviews;
  function deleteReviewHandler(reviewid) {
    axios
      .delete(ENV.api + `admins/activity/${id}/${reviewid}`, {
        headers: {
          Authorization: localStorage.getItem("jwtToken"),
        },
        mode: "cors",
      })
      .then((res) => {
        window.location.reload(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  return reviews.map((review) => (
    <ul key={review._id} className="list-review">
      <div className="activity-review">
        <Link
          style={{ textDecoration: "none" }}
          target="_blank"
          to={`/users/${review.from}`}
        >
          <li className="listitem-review1">{review.fromName}</li>
        </Link>
        <hr />
        <li className="listitem-review2"> {review.reviewtext}</li>
        <li className="listitem-review3">
          {" "}
          {review.rating}{" "}
          <FontAwesomeIcon color="gold" icon={faStar}></FontAwesomeIcon>{" "}
        </li>
        <button
          className="delete-review2"
          onClick={() => deleteReviewHandler(review._id)}
        >
          Delete Review{" "}
          <FontAwesomeIcon
            size="1x"
            color="red"
            icon={faTrash}
          ></FontAwesomeIcon>
        </button>
      </div>
    </ul>
  ));
}
function ActivitiesParticipants(props) {
  const participants = props.participants;
  return participants.map((participant, index) => (
    <div key={participant._id} className="activity-participants">
      <Link style={{ textDecoration: "none" }} to={`/users/${participant._id}`}>
        <img
          alt="_"
          className="participant-image"
          src={participant.profileImage}
        />
      </Link>

      <div>
        <Link
          style={{ textDecoration: "none" }}
          to={`/users/${participant._id}`}
        >
          <h5 className="reviewOrganizer">{participant.name}</h5>
        </Link>
      </div>
    </div>
  ));
}
export default SingleActivity;
