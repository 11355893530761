import React, { useState, useEffect } from "react";
import Navbar from "../../components/layout/navbar";
import "../../assets/styling/reviews.css";
import ENV from "../../utils/env";
import axios from "axios";
import { Link } from "react-router-dom";
import { faLongArrowAltRight, faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Pagination from "../../components/layout/pagination";
function UserReviews() {
  let reviewsPerPage;
  if (!localStorage.getItem("#ofReviews")) {
    reviewsPerPage = 5;
  } else {
    reviewsPerPage = localStorage.getItem("#ofReviews");
  }
  const [numberOfReviews, setNumberOfReviews] = useState(reviewsPerPage);
  const [pageNum, setPageNum] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [reviews, setReviews] = useState([]);
  const [totalReviews, setTotalReviews] = useState(0);
  useEffect(() => {
    axios
      .get(
        ENV.api +
          `admins/reviews/users?page=${pageNum}&limit=${numberOfReviews}`,
        {
          headers: {
            Authorization: localStorage.getItem("jwtToken"),
          },
          mode: "cors",
        },
        setIsLoading(true)
      )
      .then((res) => {
        setReviews(res.data);
        setIsLoading(false);
        setTotalReviews(res.data.totalReviews);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }, [numberOfReviews, pageNum]);
  const selectHandler = (e) => {
    setNumberOfReviews(e.target.value);
    localStorage.setItem("#ofReviews", e.target.value);
    setPageNum(1);
  };
  const clickPageHandler = (page) => {
    setPageNum(page);
  };
  if (isLoading) {
    return <div className="loader">Loading...</div>;
  } else {
    return (
      <div>
        <Navbar />
        <div className="container">
          <div className="select-input">
            <select
              value={numberOfReviews}
              onChange={selectHandler}
              id="mySelect"
            >
              <option value="5">Show 5 Reviews</option>
              <option value="10">Show 10 Reviews</option>
              <option value="20">Show 20 Reviews</option>
              <option value="50">Show 50 Reviews</option>
            </select>
          </div>
          <div className="reviews-box">
            {reviews.reviewsToSend && reviews.reviewsToSend.length > 0 && (
              <Reviews reviews={reviews.reviewsToSend} />
            )}
          </div>
          <Pagination
            reviewsPerPage={numberOfReviews}
            totalReviews={totalReviews}
            currentPage={pageNum}
            clickPageHandler={clickPageHandler}
          />
        </div>
      </div>
    );
  }
}
function Reviews(props) {
  const reviews = props.reviews;
  return reviews.map((review) => (
    <ul key={review._id} className="list-review2">
      <div className="user-review3">
        <div className="from-to-review">
          <Link
            style={{ textDecoration: "none" }}
            target="_blank"
            to={`/users/${review.from}`}
          >
            <li className="listitem-review1">{review.fromName}</li>
          </Link>
          <div className="listitem-review1" style={{ cursor: "default" }}>
            <FontAwesomeIcon icon={faLongArrowAltRight} />
          </div>
          <Link
            style={{ textDecoration: "none" }}
            target="_blank"
            to={`/users/${review.to}`}
          >
            <li className="listitem-review1">{review.toName}</li>
          </Link>
        </div>
        <hr />
        <li className="listitem-review2"> {review.reviewtext}</li>
        <li className="listitem-review3">
          {" "}
          {review.rating}{" "}
          <FontAwesomeIcon color="gold" icon={faStar}></FontAwesomeIcon>{" "}
        </li>
      </div>
    </ul>
  ));
}
export default UserReviews;
