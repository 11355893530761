import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Navbar from "../../components/layout/navbar";
import "../../assets/styling/landing.css";
import ENV from "../../utils/env";
function Landing(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const onChangeHandler = (e) => {
    if (e.target.name === "email") {
      setEmail(e.target.value);
    } else {
      setPassword(e.target.value);
    }
  };
  const OnLoginHandler = (e) => {
    e.preventDefault();
    const form = {
      email,
      password,
    };
    axios
      .post(ENV.api + "admins/login", form)
      .then((res) => {
        const token = res.data.token;
        localStorage.setItem("jwtToken", token);
        props.setIsAuth(true);
        props.history.push("/");
        //window.location.reload();
      })
      .catch((error) => {
        setError("The email or password you entered is incorrect.");
      });
  };
  if (!props.Auth) {
    return (
      <div className="container">
        <div className="logo">
          <div>
            <div className="circles">
              <div className="circle"></div>
              <div className="circle"></div>
            </div>
            <span className="top">gr</span>
          </div>
        </div>
        <div className="center">
          <div className="login-form">
            <p>
              Admin <b style={{ color: "green" }}>Login</b>
            </p>
            <hr></hr>
            <form>
              <div>
                <h4>Email</h4>
                <input
                  className="form-input"
                  type="text"
                  name="email"
                  required
                  value={email}
                  onChange={onChangeHandler}
                />
              </div>
              <div>
                <h4>Password</h4>
                <input
                  className="form-input"
                  type="password"
                  name="password"
                  required
                  onChange={onChangeHandler}
                  value={password}
                />
              </div>
              <div className="btn-left">
                <button className="btn" onClick={OnLoginHandler}>
                  Login
                </button>
              </div>
            </form>
            <h5 className="error-message">
              {error && <FontAwesomeIcon icon={faTimesCircle} />}
              {" " + error}{" "}
            </h5>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <Navbar />
        <div className="container">
          <div className="logo2">
            <span>groupify</span>
          </div>
          <div className="user-review" style={{ marginBottom: "25px" }}>
            <div className="logo2 ">
              {" "}
              <p style={{ padding: "0px" }}>Welcome to the admins dashboard</p>
            </div>

            <div className="center-txt">
              {" "}
              <h5>
                You're currently logged in as{" "}
                <b style={{ color: "green" }}>{props.name}</b>
              </h5>
            </div>
            <hr />
            <div className="dashboard-options">
              <div className="center-text">
                {" "}
                <Link to="/editPassword" style={{ textDecoration: "none" }}>
                  <h5 style={{ color: "#a72b2b" }}>Change Password?</h5>
                </Link>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Landing;
