import React, { useState } from "react";
import axios from "axios";
import "../../assets/styling/users.css";
import { Link } from "react-router-dom";
import Navbar from "../../components/layout/navbar";
import {
  faBan,
  faDoorClosed,
  faDoorOpen,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ENV from "../../utils/env";
function Activities(props) {
  const [Activities, setActivities] = useState([]);
  const [address, setAddress] = useState("");
  const [title, setTitle] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const fetchActivities = () => {
    axios
      .get(
        ENV.api +
          `admins/AllActivities?searchTitle=${title}&searchAddress=${address}`,
        {
          headers: {
            Authorization: localStorage.getItem("jwtToken"),
          },
          mode: "cors",
        },
        setIsLoading(true)
      )
      .then((res) => {
        setActivities(res.data);
        setIsLoading(false);
        setTitle("");
        setAddress("");
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };
  const changeHandler = (e) => {
    if (e.target.name === "activityTitle") {
      setTitle(e.target.value);
    }
    if (e.target.name === "activityAddress") {
      setAddress(e.target.value);
    }
  };
  if (isLoading) {
    return <div className="loader">Loading...</div>;
  } else {
    return (
      <div>
        <Navbar />
        <div className="container">
          <div className="search-users">
            <div className="search-form">
              <h4>Search activities by address</h4>
              <input
                className="form-input2"
                type="text"
                name="activityAddress"
                onChange={changeHandler}
              />
            </div>
            <div className="search-form">
              <h4>Search activities by title</h4>
              <input
                className="form-input2"
                type="text"
                name="activityTitle"
                onChange={changeHandler}
              />
            </div>
            <div
              className="user-search-btns"
              style={{ position: "relative", top: "20px" }}
            >
              <button onClick={fetchActivities}>Search Activities</button>
            </div>
          </div>
          <div></div>
          <br />
          <AllActivities listOfActivities={Activities} />
        </div>
      </div>
    );
  }
}
export default Activities;

function AllActivities(props) {
  const activities = props.listOfActivities;
  return activities.map((activity, index) => (
    <ActivityItem
      key={activity._id}
      index={index}
      activity={activity}
      len={activities.length}
    />
  ));
}

function ActivityItem(props) {
  const len = props.len;
  const activity = props.activity;
  const index = props.index;
  return (
    <div>
      <div className="userItem">
        <div className="flip-box-activities">
          <div className="flip-box-inner">
            <div className="flip-box-front">
              {activity.activityStatus === "Closed" && (
                <div className="chip">
                  <FontAwesomeIcon
                    icon={faDoorClosed}
                    color={"red"}
                    style={{ marginRight: "10px" }}
                  />
                  Closed
                </div>
              )}
              {activity.activityStatus === "Open" && (
                <div className="chip">
                  <FontAwesomeIcon
                    icon={faDoorOpen}
                    color={"green"}
                    size="1x"
                    style={{ marginRight: "10px" }}
                  />
                  Open
                </div>
              )}
              {activity.activityStatus === "Cancelled" && (
                <div className="chip">
                  <FontAwesomeIcon
                    icon={faBan}
                    color={"red"}
                    size="1x"
                    style={{ marginRight: "5px" }}
                  />
                  Cancelled
                </div>
              )}
              {activity.activityStatus === "Full" && (
                <div className="chip">
                  <FontAwesomeIcon
                    icon={faUsers}
                    color={"green"}
                    size="1x"
                    style={{ marginRight: "10px" }}
                  />
                  Full
                </div>
              )}
            </div>
            <div className="flip-box-back">
              <h5 style={{ fontSize: "10px" }}>{activity._id}</h5>
            </div>
          </div>
        </div>

        <div className="text-wrapper2">
          {" "}
          <h4 className="user-name-text">{activity.title}</h4>
        </div>

        <Link to={`/activities/${activity._id}`}>
          <button className="FlexBtn">View Activity</button>
        </Link>
      </div>
      {index < len - 1 && (
        <hr
          style={{
            width: "100%",
            margin: "0",
            marginBottom: "5px",
          }}
        />
      )}
    </div>
  );
}
