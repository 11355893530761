import React, { useState, useEffect } from 'react';
import './assets/styling/App.css';
//import Navbar from './components/layout/navbar';
//import Footer from './components/layout/footer';
import Landing from './Screens/landing/landing';
import Users from './Screens/users/users';
import Activities from './Screens/activities/activities';
import SingleUser from './Screens/users/singleUser';
import SingleActivity from './Screens/activities/singleActivity';
//import setAuthToken from "./utils/setAuthToken";
import jwt_decode from 'jwt-decode';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
//import axios from 'axios'
import PrivateRoute from './utils/privateRoute';
import EditPassword from './Screens/landing/editPassword';
//import { faEdit } from '@fortawesome/free-solid-svg-icons';
//import { CSSTransition, TransitionGroup } from "react-transition-group";
import Interests from './Screens/interests/interests';
import AddInterest from './Screens/interests/addInterest';
import EditInterest from './Screens/interests/editInterest';
import Dashboard from './Screens/dashboard/dashboard';
import Reviews from './Screens/reviews/reviews';
import UserReviews from './Screens/reviews/usersReviews';
import ActivityReviews from './Screens/reviews/activityReviews';
import ResetPassword from './Screens/resetPassword/resetPassword';
function App() {
  const [isAuth, setIsAuth] = useState(true);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  useEffect(() => {
    if (localStorage.jwtToken) {
      //setAuthToken(localStorage.jwtToken);
      const decoded_data = jwt_decode(localStorage.jwtToken);
      const currentTime = Date.now() / 1000;
      setName(decoded_data.name);
      setEmail(decoded_data.email);
      if (decoded_data.exp < currentTime) {
        setIsAuth(false);
      }
    } else {
      setIsAuth(false);
    }
  }, []);
  return (
    <Router>
      <Route
        exact
        path='/home'
        render={(props) => (
          <Landing
            Auth={isAuth}
            email={email}
            name={name}
            setIsAuth={setIsAuth}
            {...props}
          />
        )}
      />
      <Route
        exact
        path='/authenticate/reset-password/:resetLink'
        component={ResetPassword}
      />
      <Switch>
        <PrivateRoute authed={isAuth} exact path='/users' component={Users} />
      </Switch>
      <Switch>
        <PrivateRoute
          authed={isAuth}
          exact
          path='/activities'
          component={Activities}
        />
      </Switch>
      <Switch>
        <PrivateRoute
          authed={isAuth}
          exact
          path='/users/:id'
          component={SingleUser}
        />
      </Switch>
      <Switch>
        <PrivateRoute
          authed={isAuth}
          exact
          path='/activities/:id'
          component={SingleActivity}
        />
      </Switch>
      <Switch>
        <PrivateRoute
          authed={isAuth}
          exact
          path='/editPassword'
          component={EditPassword}
        />
      </Switch>
      <Switch>
        <PrivateRoute
          authed={isAuth}
          exact
          path='/interests'
          component={Interests}
        />
      </Switch>
      <Switch>
        <PrivateRoute
          authed={isAuth}
          exact
          path='/add-interest'
          component={AddInterest}
        />
      </Switch>
      <Switch>
        <PrivateRoute
          authed={isAuth}
          exact
          path='/edit-interest/:id'
          component={EditInterest}
        />
      </Switch>
      <Switch>
        <PrivateRoute authed={isAuth} exact path='/' component={Dashboard} />
      </Switch>
      <Switch>
        <PrivateRoute
          authed={isAuth}
          exact
          path='/reviews'
          component={Reviews}
        />
      </Switch>
      <Switch>
        <PrivateRoute
          authed={isAuth}
          exact
          path='/reviews/users'
          component={UserReviews}
        />
      </Switch>
      <Switch>
        <PrivateRoute
          authed={isAuth}
          exact
          path='/reviews/activities'
          component={ActivityReviews}
        />
      </Switch>
    </Router>
  );
}

export default App;
