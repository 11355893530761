import React, { useState } from "react";
import "../../assets/styling/landing.css";
import Navbar from "../../components/layout/navbar";
import axios from "axios";
import { faTimesCircle, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ENV from "../../utils/env";
function EditPassword(props) {
  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [errors, setErrors] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [passwordReset, setPasswordReset] = useState("");
  const [direct, setDirect] = useState("");
  const [disbaled, setDisabled] = useState(false);
  const onChangeHandler = (e) => {
    if (e.target.name === "oldPassword") {
      setOldPassword(e.target.value);
    }
    if (e.target.name === "password") {
      setPassword(e.target.value);
    }
    if (e.target.name === "password2") {
      setPassword2(e.target.value);
    }
  };
  const OnClickHandler = (e) => {
    e.preventDefault();
    const form = {
      oldPassword,
      password,
      password2,
    };
    axios
      .put(ENV.api + "admins/EditAdminPassword", form, {
        headers: {
          Authorization: localStorage.getItem("jwtToken"),
        },
        mode: "cors",
      })
      .then((res) => {
        var t = 5;
        setPasswordReset("Password reset sucessfully");
        setDirect(`You will be directed back to the dashboard in ${t} seconds`);
        setDisabled(true);
        setPassword("");
        setPassword2("");
        setOldPassword("");
        setErrors("");
        var interval = setInterval(() => {
          t = t - 1;
          setDirect(
            `You will be directed back to the dashboard in ${t} seconds`
          );
          if (t === 0) {
            clearInterval(interval);
          }
        }, 1000);
        setTimeout(() => {
          if (window.location.pathname === "/editPassword") {
            props.history.push("/");
          }
        }, 5000);
        //props.history.push('/users');
        //window.location.reload();
      })
      .catch((error) => {
        setIsLoading(false);
        setErrors(error.response.data.msg);
      });
  };
  if (isLoading) {
    return <div className="loader">Loading...</div>;
  } else {
    return (
      <div>
        <Navbar />
        <div className="container">
          <div className="center">
            <div className="login-form">
              <p>Change Password</p>
              <form>
                <div>
                  <h4>Current Password</h4>
                  <input
                    className="form-input"
                    type="password"
                    name="oldPassword"
                    value={oldPassword}
                    onChange={onChangeHandler}
                    required
                    disabled={disbaled}
                  />
                </div>
                <div>
                  <h4>New Password</h4>
                  <input
                    className="form-input"
                    type="password"
                    name="password"
                    disabled={disbaled}
                    required
                    value={password}
                    onChange={onChangeHandler}
                  />
                </div>
                <div>
                  <h4>Confirm Password</h4>
                  <input
                    className="form-input"
                    type="password"
                    name="password2"
                    required
                    value={password2}
                    disabled={disbaled}
                    onChange={onChangeHandler}
                  />
                </div>
                <div className="btn-left">
                  <button className="btn" onClick={OnClickHandler}>
                    Submit
                  </button>
                </div>
              </form>
              <h5 className="error-message">
                {errors && <FontAwesomeIcon icon={faTimesCircle} />}
                {" " + errors}{" "}
              </h5>
              {passwordReset && (
                <h5 className="success-message">
                  <FontAwesomeIcon icon={faCheck} />
                  {" " + passwordReset}{" "}
                </h5>
              )}
              {direct && <h5 className="success-message">{" " + direct} </h5>}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EditPassword;
