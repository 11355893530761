import React, { useState, useEffect } from "react";
import Navbar from "../../components/layout/navbar";
import { Link } from "react-router-dom";
import axios from "axios";
import ENV from "../../utils/env";
import { Bar } from "react-chartjs-2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBiking,
  faCommentDots,
  faSquare,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import "../../assets/styling/dashboard.css";
import { PieChart } from "react-minimal-pie-chart";
import LineChart from "./loginsChart";
function Dashboard() {
  const [stats, setStats] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [showWeekStats, setShowWeekStats] = useState(false);
  useEffect(() => {
    axios
      .get(
        ENV.api + "admins/stats",
        {
          headers: {
            Authorization: localStorage.getItem("jwtToken"),
          },
          mode: "cors",
        },
        setIsLoading(true)
      )
      .then((res) => {
        setStats(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }, []);
  if (
    isLoading ||
    !stats ||
    isNaN(stats.numOfOpenActivities) ||
    isNaN(stats.numOfClosedActivities) ||
    isNaN(stats.numOfFullActivities) ||
    isNaN(stats.numOfCancelledActivities) ||
    isNaN(stats.totalMaleUsers) ||
    isNaN(stats.totalFemaleUsers) ||
    isNaN(stats.totalOtherUsers) ||
    isNaN(stats.numOfActivitiesForM) ||
    isNaN(stats.numOfActivitiesForF) ||
    isNaN(stats.numOfActivitiesForA)
  ) {
    return <div className="loader">Loading...</div>;
  } else {
    return (
      <div>
        <Navbar />
        <div className="container">
          <div className="statsCards">
            <Link
              to="/users"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <div className="statsCard">
                <div className="statsIcon">
                  <FontAwesomeIcon icon={faUsers} color="green" />
                </div>
                <div className="statsInfo">
                  {stats.numOfUsers && <h2>{stats.numOfUsers}</h2>}
                  <h1>Registrations</h1>
                  <h3>
                    {stats.userTypeIndividual} Individuals -{" "}
                    {stats.userTypeOrganization} Organization
                  </h3>
                </div>
              </div>
            </Link>
            <Link
              to="/activities"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <div className="statsCard">
                <div className="statsIcon">
                  <FontAwesomeIcon icon={faBiking} color="orange" />
                </div>
                <div className="statsInfo">
                  {stats.numOfActivities && <h2>{stats.numOfActivities}</h2>}
                  <h1>Activities</h1>
                  <h3>Total {stats.totalParticipants} Participations</h3>
                </div>
              </div>
            </Link>
            <Link
              to={{
                pathname: "/reviews",
                state: {
                  reviewsToActivities: stats.totalActivitiesReviews,
                  reviewsToUsers: stats.userReviews,
                },
              }}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <div className="statsCard">
                <div className="statsIcon">
                  <FontAwesomeIcon
                    icon={faCommentDots}
                    color="rgb(0,181,184)"
                  />
                </div>
                <div className="statsInfo">
                  {stats.totalActivitiesReviews && stats.userReviews && (
                    <h2>{stats.totalActivitiesReviews + stats.userReviews}</h2>
                  )}
                  <h1>Reviews</h1>
                  <h3>
                    {stats.userReviews} to users -{" "}
                    {stats.totalActivitiesReviews} to activities
                  </h3>
                </div>
              </div>
            </Link>
          </div>
          <div className="charts">
            <div className="logins-chart">
              <div style={{ display: "flex" }}>
                <h1 className="total-logins">
                  Total Logins {stats.numOfLogins}
                </h1>
                <div
                  className="legend2"
                  onClick={() => setShowWeekStats(!showWeekStats)}
                >
                  <h1>Past {showWeekStats ? "week" : "month"} logins </h1>
                </div>
              </div>

              <div className="line-chart">
                <LineChart
                  logins={showWeekStats ? stats.weekLogins : stats.monthLogins}
                />
              </div>
            </div>
            <div className="chart">
              <PieChart
                data={[
                  {
                    title: "Open",
                    value: stats.numOfOpenActivities,
                    color: "#40A91E",
                  },
                  {
                    title: "Closed",
                    value: stats.numOfClosedActivities,
                    color: "#207476",
                  },
                  {
                    title: "Cancelled",
                    value: stats.numOfCancelledActivities,
                    color: "#FF0000",
                  },
                  {
                    title: "Full",
                    value: stats.numOfFullActivities,
                    color: "#86A495",
                  },
                ]}
                viewBoxSize={[150, 100]}
                lineWidth={50}
                paddingAngle={2}
                rounded={true}
                animate={true}
                background="rgb(228,233,220)"
                label={({ dataEntry }) => {
                  let percentage;
                  Math.round(dataEntry.percentage) === 0
                    ? (percentage = "")
                    : (percentage =
                        Math.round(dataEntry.percentage).toString() + "%");
                  return percentage;
                }}
                labelPosition={70}
                labelStyle={{
                  fontSize: "8px",
                  fontFamily: "sans-serif",
                  fill: "rgb(228,233,220)",
                }}
              />
              <div className="legend">
                <p>Activities Status</p>
                <div className="legend-info">
                  <h1>
                    <FontAwesomeIcon
                      icon={faSquare}
                      className="legend-icon"
                      color="#40A91E"
                    />
                    {stats.numOfOpenActivities} Open
                  </h1>
                  <h1>
                    <FontAwesomeIcon
                      icon={faSquare}
                      className="legend-icon"
                      color="#207476"
                    />
                    {stats.numOfClosedActivities} Closed
                  </h1>
                  <h1>
                    <FontAwesomeIcon
                      icon={faSquare}
                      className="legend-icon"
                      color="#FF0000"
                    />
                    {stats.numOfCancelledActivities} Cancelled
                  </h1>
                  <h1>
                    <FontAwesomeIcon
                      icon={faSquare}
                      className="legend-icon"
                      color="#86A495"
                    />
                    {stats.numOfFullActivities} Full
                  </h1>
                </div>
              </div>
            </div>
            <div className="chart">
              <PieChart
                data={[
                  {
                    title: "Male",
                    value: stats.totalMaleUsers,
                    color: "#184F76",
                  },
                  {
                    title: "Females",
                    value: stats.totalFemaleUsers,
                    color: "#FF0F70",
                  },
                  {
                    title: "Others",
                    value: stats.totalOtherUsers,
                    color: "#86A495",
                  },
                ]}
                viewBoxSize={[150, 100]}
                lineWidth={50}
                paddingAngle={2}
                rounded={true}
                animate={true}
                background="rgb(228,233,220)"
                label={({ dataEntry }) => {
                  let percentage;
                  Math.round(dataEntry.percentage) === 0
                    ? (percentage = "")
                    : (percentage =
                        Math.round(dataEntry.percentage).toString() + "%");
                  return percentage;
                }}
                labelPosition={70}
                labelStyle={{
                  fontSize: "8px",
                  fontFamily: "sans-serif",
                  fill: "rgb(228,233,220)",
                }}
              />
              <div className="legend">
                <p>Users Gender</p>
                <div className="legend-info">
                  <h1>
                    <FontAwesomeIcon
                      icon={faSquare}
                      className="legend-icon"
                      color="#184F76"
                    />
                    {stats.totalMaleUsers} Males
                  </h1>
                  <h1>
                    <FontAwesomeIcon
                      icon={faSquare}
                      className="legend-icon"
                      color="#FF0F70"
                    />
                    {stats.totalFemaleUsers} Females
                  </h1>
                  <h1>
                    <FontAwesomeIcon
                      icon={faSquare}
                      className="legend-icon"
                      color="#86A495"
                    />
                    {stats.totalOtherUsers} Others
                  </h1>
                </div>
              </div>
            </div>
            <div className="chart">
              <PieChart
                data={[
                  {
                    title: "Males",
                    value: stats.numOfActivitiesForM,
                    color: "#184F76",
                  },
                  {
                    title: "Females",
                    value: stats.numOfActivitiesForF,
                    color: "#FF0F70",
                  },
                  {
                    title: "All",
                    value: stats.numOfActivitiesForA,
                    color: "#86A495",
                  },
                ]}
                viewBoxSize={[150, 100]}
                lineWidth={50}
                paddingAngle={2}
                rounded={true}
                animate={true}
                background="rgb(228,233,220)"
                label={({ dataEntry }) => {
                  let percentage;
                  Math.round(dataEntry.percentage) === 0
                    ? (percentage = "")
                    : (percentage =
                        Math.round(dataEntry.percentage).toString() + "%");
                  return percentage;
                }}
                labelPosition={70}
                labelStyle={{
                  fontSize: "8px",
                  fontFamily: "sans-serif",
                  fill: "rgb(228,233,220)",
                }}
              />
              <div className="legend">
                <p>Activities by gender </p>
                <div className="legend-info">
                  <h1>
                    <FontAwesomeIcon
                      icon={faSquare}
                      className="legend-icon"
                      color="#184F76"
                    />
                    {stats.numOfActivitiesForM} Males
                  </h1>
                  <h1>
                    <FontAwesomeIcon
                      icon={faSquare}
                      className="legend-icon"
                      color="#FF0F70"
                    />
                    {stats.numOfActivitiesForF} Females
                  </h1>
                  <h1>
                    <FontAwesomeIcon
                      icon={faSquare}
                      className="legend-icon"
                      color="#86A495"
                    />
                    {stats.numOfActivitiesForA} All
                  </h1>
                </div>
              </div>
            </div>
            <div className="chart2">
              <div className="legend2">
                <h1>Users by age </h1>
              </div>
              <div className="bar-chart">
                <BarChart userAges={stats.userAges} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Dashboard;

function BarChart({ userAges }) {
  const labels = ["15-18", "19-25", "26-40", "41-55", "56-70", "71-100"];
  let AgesMap = new Map();
  const labelsLists = [];
  for (let i in labels) {
    labelsLists.push(labels[i].split("-"));
    AgesMap.set(labels[i], 0);
  }
  for (let age in userAges) {
    for (let interval in labelsLists) {
      if (
        Number(userAges[age]) >= labelsLists[interval][0] &&
        Number(userAges[age]) <= labelsLists[interval][1]
      ) {
        AgesMap.set(labels[interval], AgesMap.get(labels[interval]) + 1);
        continue;
      }
    }
  }
  let valuesData = [...AgesMap.values()];
  let maxVal = Math.max(...valuesData);
  let minVal = Math.min(...valuesData);
  const data = {
    labels: ["15-18", "18-25", "25-40", "40-55", "55-70", "70 & More"],
    datasets: [
      {
        data: valuesData,
        backgroundColor: "rgba(0,128,0,0.2)",
        borderColor: "rgba(0,128,0,8)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(0,128,0,0.5)",
        hoverBorderColor: "rgba(0,128,1)",
      },
    ],
  };

  const options = {
    tooltips: {
      mode: "nearest",
    },
    cornerRadius: 20,
    legend: {
      display: false,
    },
    title: {
      display: false,
    },

    scales: {
      yAxes: [
        {
          ticks: {
            min: minVal,
            max: maxVal,
            stepSize: Math.ceil((maxVal - minVal) / 5),
            fontColor: "green",
            fontSize: 15,
            beginAtZero: true,
            padding: 5,
          },
          gridLines: {
            drawOnChartArea: false,
            drawBorder: true,
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            drawOnChartArea: false,
            drawBorder: true,
          },
          ticks: {
            fontColor: "green",
            fontSize: 15,
            stepSize: 1,
            beginAtZero: true,
            padding: 5,
          },
        },
      ],
    },
  };
  return <Bar data={data} options={options} />;
}
