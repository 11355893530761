import React, { useState } from "react";
import "../../assets/styling/navbar.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
function Navbar() {
  const logoutHandler = () => {
    localStorage.removeItem("jwtToken");
    window.location.reload();
  };
  const [showDropDown, setShowDropDown] = useState(false);
  return (
    <div>
      <nav className="a">
        <div className="container">
          <ul className="navBarItems">
            <li className="listitem2">
              {" "}
              <button
                className="hamburger-icon"
                onClick={() => setShowDropDown(!showDropDown)}
              >
                <FontAwesomeIcon icon={faBars} />
              </button>
              {showDropDown && (
                <div className="dropdown">
                  {" "}
                  <button>
                    <Link to="/" style={{ textDecoration: "none" }}>
                      <h3 className="text-styling">Dashboard</h3>
                    </Link>
                  </button>
                  <button>
                    <Link to="/home" style={{ textDecoration: "none" }}>
                      <h3 className="text-styling">Home</h3>
                    </Link>
                  </button>
                  <button>
                    <Link to="/users" style={{ textDecoration: "none" }}>
                      <h3 className="text-styling">Users</h3>
                    </Link>
                  </button>
                  <button>
                    <Link to="/activities" style={{ textDecoration: "none" }}>
                      <h3 className="text-styling">Activities</h3>
                    </Link>
                  </button>
                  <button>
                    <Link to="/interests" style={{ textDecoration: "none" }}>
                      <h3 className="text-styling">Interests</h3>
                    </Link>
                  </button>
                  <button onClick={logoutHandler}>
                    {" "}
                    <h3 className="text-styling">Logout</h3>
                  </button>
                </div>
              )}
            </li>
            <li className="listitem2">
              {" "}
              <Link to="/">
                <button className="button2">Dashboard</button>
              </Link>
            </li>
            <li className="listitem2">
              {" "}
              <Link to="/home">
                <button className="button2">Home</button>
              </Link>
            </li>
            <li className="listitem2">
              <Link to="/users">
                <button className="button2">Users</button>
              </Link>
            </li>
            <li className="listitem2">
              {" "}
              <Link to="/activities">
                <button className="button2">Activities</button>
              </Link>
            </li>
            <li className="listitem2">
              {" "}
              <Link to="/interests">
                <button className="button2">Interests</button>
              </Link>
            </li>
            <li className="listitem1">
              <button className="button1" onClick={logoutHandler}>
                Logout{" "}
                <FontAwesomeIcon icon={faSignOutAlt} className="logout-icon" />
              </button>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
