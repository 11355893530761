import React from "react";
import Navbar from "../../components/layout/navbar";
import { useLocation } from "react-router-dom";
import "../../assets/styling/reviews.css";
import { Link } from "react-router-dom";
import Heart from "../../assets/images/iconmonstr-favorite-2-240.png";
import Review from "../../assets/images/iconmonstr-speech-bubble-35-240.png";
function Reviews() {
  let data = useLocation();
  return (
    <div>
      <Navbar />
      <div className="container">
        <div className="reviews-options-wrapper">
          <div className="reviews-option">
            <img src={Heart} className="reviews-icon" alt="_" />
            <h2 className="to-go-up">Reviews to users</h2>
            <h1 className="to-appear">
              {data.state.reviewsToUsers} reviews have been submitted
            </h1>

            <Link to="/reviews/users">
              <button>Check reviews</button>
            </Link>
          </div>
          <div className="reviews-option">
            <img src={Review} className="reviews-icon" alt="_" />
            <h2 className="to-go-up">Reviews to activities</h2>
            <h1 className="to-appear">
              {data.state.reviewsToActivities} reviews have been submitted
            </h1>
            <Link to="/reviews/activities">
              <button className="check-activities-reviews">
                Check reviews
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Reviews;
