import React, { useState } from "react";
import Navbar from "../../components/layout/navbar";
import { faPlus, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../assets/styling/addInterest.css";
import { useHistory } from "react-router-dom";
import axios from "axios";
import ENV from "../../utils/env";
let image;
function AddInterest() {
  let history = useHistory();
  const [errors, setErrors] = useState("");
  const [title, setTitle] = useState("");
  const [titleDe, setGermanTitle] = useState("");
  const [chosenImageName, setChosenImageName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const inputhandler = (e) => {
    if (e.target.name === "title") {
      setTitle(e.target.value);
    } else if (e.target.name === "titleDe") {
      setGermanTitle(e.target.value);
    } else {
      image = e.target.files[0];
      setChosenImageName(image.name);
    }
  };
  const submitAddInterest = (e) => {
    e.preventDefault();
    if (!image) {
      setErrors("Icon is required.");
      return;
    }
    if (!title) {
      setErrors("Title is required.");
      return;
    }
    if (!titleDe) {
      setErrors("German title is required.");
      return;
    } else {
      const formdata = new FormData();
      formdata.append("title", title);
      formdata.append("titleDe", titleDe);
      formdata.append("iconImage", image);
      formdata.append("category", " ");
      axios
        .post(
          ENV.api + "admins/addInterest",
          formdata,
          {
            headers: {
              Authorization: localStorage.getItem("jwtToken"),
            },
            mode: "cors",
          },
          setIsLoading(true)
        )
        .then((res) => {
          setIsLoading(false);
          history.push("/interests");
        })
        .catch((err) => {
          setErrors(err.response.data.msg);
          setIsLoading(false);
        });
    }
  };
  if (isLoading) {
    return <div className="loader">Loading...</div>;
  } else {
    return (
      <div>
        <Navbar />
        <div className="container">
          <div className="center">
            <div className="login-form">
              <p>Add Interest</p>
              <form>
                <h4>Interest Icon</h4>
                <div className="wrapper">
                  <div className="file-upload">
                    <input type="file" name="icon" onChange={inputhandler} />
                    <FontAwesomeIcon icon={faPlus} />
                  </div>
                </div>
                {chosenImageName && (
                  <h4 style={{ color: "grey" }}>* {chosenImageName}</h4>
                )}
                <div>
                  <h4>Interest Title</h4>
                  <input
                    className="form-input"
                    type="text"
                    name="title"
                    value={title}
                    onChange={inputhandler}
                  />
                </div>
                <div>
                  <h4>Interest german title</h4>
                  <input
                    className="form-input"
                    type="text"
                    name="titleDe"
                    value={titleDe}
                    onChange={inputhandler}
                  />
                </div>
                <div className="btn-left">
                  <button className="btn" onClick={submitAddInterest}>
                    Submit
                  </button>
                </div>
              </form>
              <h5 className="error-message">
                {errors && <FontAwesomeIcon icon={faTimesCircle} />}
                {" " + errors}{" "}
              </h5>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AddInterest;
