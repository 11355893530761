import React, { useState } from 'react';
import '../../assets/styling/landing.css';
import axios from 'axios';
import { faTimesCircle, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ENV from '../../utils/env';
import { useParams } from 'react-router-dom';

function ResetPassword(props) {
  const { resetLink } = useParams();
  const queryParams = new URLSearchParams(window.location.search);
  const lang = queryParams.get('lang') || 'EN';
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [errors, setErrors] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [passwordReset, setPasswordReset] = useState('');
  const onChangeHandler = (e) => {
    if (e.target.name === 'password') {
      setPassword(e.target.value);
    }
    if (e.target.name === 'password2') {
      setPassword2(e.target.value);
    }
  };
  const OnClickHandler = (e) => {
    e.preventDefault();
    const form = {
      resetLink,
      password,
      password2,
    };
    axios
      .put(ENV.api + 'users/resetPassword', form, {
        mode: 'cors',
      })
      .then((res) => {
        setIsLoading(false);
        let message =
          lang === 'DE'
            ? 'Dein Passwort wurde zurückgesetzt. Bitte logge dich neu in der groupify App ein.'
            : 'Your password has been reset. Please log in to the groupify app again';
        setPasswordReset(message);
        setPassword('');
        setPassword2('');
        setErrors('');
      })
      .catch((error) => {
        setIsLoading(false);
        setErrors(error.response.data.msg);
      });
  };
  if (isLoading) {
    return (
      <div className='loader'>{lang === 'DE' ? 'Lädt...' : 'Loading...'}</div>
    );
  } else {
    return (
      <div>
        <div className='container'>
          <div className='center'>
            <div className='login-form'>
              <p>
                {lang === 'DE' ? 'Passwort zurücksetzen' : 'Reset Password'}
              </p>
              {!passwordReset && (
                <form>
                  <div>
                    <h4>{lang === 'DE' ? 'Neues Passwort' : 'New Password'}</h4>
                    <input
                      className='form-input'
                      type='password'
                      name='password'
                      value={password}
                      onChange={onChangeHandler}
                      required
                    />
                  </div>
                  <div>
                    <h4>
                      {lang === 'DE'
                        ? 'Passwort bestätigen'
                        : 'Confirm Password'}
                    </h4>
                    <input
                      className='form-input'
                      type='password'
                      name='password2'
                      required
                      value={password2}
                      onChange={onChangeHandler}
                    />
                  </div>
                  <div className='btn-left'>
                    <button className='btn' onClick={OnClickHandler}>
                      {lang === 'DE' ? 'Zurücksetzen' : 'Submit'}
                    </button>
                  </div>
                </form>
              )}
              <h5 className='error-message'>
                {errors && <FontAwesomeIcon icon={faTimesCircle} />}
                {' ' + errors}{' '}
              </h5>
              {passwordReset && (
                <h5 className='success-message'>
                  <FontAwesomeIcon icon={faCheck} />
                  {' ' + passwordReset}{' '}
                </h5>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ResetPassword;
