import React, { useState, useEffect } from "react";
import axios from "axios";
import "../../assets/styling/interests.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "react-modal";
import { Link } from "react-router-dom";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import Navbar from "../../components/layout/navbar";
import ENV from "../../utils/env";
function Interests() {
  const [interests, setInterests] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteInterestModal, setDeleteInterestModal] = useState(false);
  const [selectedInterest, setSelectedInterest] = useState("");

  useEffect(() => {
    axios
      .get(
        ENV.api + `users/interests`,
        {
          headers: {
            Authorization: localStorage.getItem("jwtToken"),
          },
          mode: "cors",
        },
        setIsLoading(true)
      )
      .then((res) => {
        setInterests(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }, []);
  const deleteInterest = (id) => {
    axios
      .delete(
        ENV.api + `admins/deleteInterest/${id}`,
        {
          headers: {
            Authorization: localStorage.getItem("jwtToken"),
          },
          mode: "cors",
        },
        setIsLoading(true)
      )
      .then((res) => {
        setIsLoading(false);
        window.location.reload();
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
  Modal.setAppElement("#root");
  if (isLoading) {
    return <div className="loader">Loading...</div>;
  } else {
    return (
      <div>
        <Navbar />
        <div className="container">
          <Modal
            isOpen={deleteInterestModal}
            onRequestClose={() => setDeleteInterestModal(false)}
            style={{
              overlay: { backgroundColor: "rgba(228,233,220,0.7)" },
              content: {
                top: "30%",
                left: "50%",
                right: "auto",
                bottom: "auto",
                marginRight: "-50%",
                transform: "translate(-50%, -50%)",
              },
            }}
            shouldCloseOnOverlayClick={false}
          >
            <p>Are you sure you want to delete this interest? </p>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <button
                className="btn-no"
                onClick={() => setDeleteInterestModal(false)}
              >
                No{" "}
              </button>
              <button
                className="btn-delete"
                onClick={() => deleteInterest(selectedInterest)}
              >
                Yes{" "}
              </button>
            </div>
          </Modal>
          <div className="cards">
            {interests.length === 0 && (
              <h4>No interests have been added yet.</h4>
            )}
            {interests.map((interest) => {
              return (
                <div className="card" key={interest._id}>
                  <div className="multi-button">
                    <button>
                      <Link to={`edit-interest/${interest._id}`}>
                        {" "}
                        <FontAwesomeIcon icon={faEdit} color="green" />
                      </Link>
                    </button>
                    <button
                      onClick={() => {
                        setSelectedInterest(interest._id);
                        setDeleteInterestModal(true);
                      }}
                    >
                      <FontAwesomeIcon icon={faTrash} color="red" />
                    </button>
                  </div>

                  <div className="container2">
                    <img
                      src={interest.icon}
                      alt="_"
                    />
                    <h1>{interest.title}</h1>
                  </div>
                </div>
              );
            })}
          </div>
          <Link to="/add-interest" style={{ textDecoration: "none" }}>
            <div className="btn-left">
              <button className="btn">Add an interest</button>
            </div>
          </Link>
        </div>
      </div>
    );
  }
}

export default Interests;
