import React, { useState } from "react";
import axios from "axios";
import "../../assets/styling/users.css";
import { Link } from "react-router-dom";
import Navbar from "../../components/layout/navbar";
import ENV from "../../utils/env";
function Users() {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showIndividuals, setShowIndividuals] = useState(false);
  const [showOrganizations, setShowOrganizations] = useState(false);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const fetchUsers = () => {
    let type;
    if (showOrganizations) {
      type = "1";
    }
    if (showIndividuals) {
      type = "0";
    }
    if (!showIndividuals && !showOrganizations) {
      type = "";
    }
    axios
      .get(
        ENV.api +
          `admins/allUsers?searchName=${name}&email=${email}&type=${type}`,
        {
          headers: {
            Authorization: localStorage.getItem("jwtToken"),
          },
          mode: "cors",
        },
        setIsLoading(true)
      )
      .then((res) => {
        setUsers(res.data);
        setIsLoading(false);
        setName("");
        setEmail("");
        setShowIndividuals(false);
        setShowOrganizations(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
  const changeHandler = (e) => {
    if (e.target.name === "userEmail") {
      setEmail(e.target.value);
    }
    if (e.target.name === "userName") {
      setName(e.target.value);
    }
    if (e.target.name === "individuals") {
      setShowIndividuals(!showIndividuals);
      setShowOrganizations(false);
    }
    if (e.target.name === "organizations") {
      setShowIndividuals(false);
      setShowOrganizations(!showOrganizations);
    }
  };
  if (isLoading) {
    return <div className="loader">Loading...</div>;
  } else {
    return (
      <div>
        <Navbar />
        <div className="container">
          <div className="search-users">
            <div className="search-form">
              <h4>Search users by email</h4>
              <input
                className="form-input2"
                type="text"
                name="userEmail"
                onChange={changeHandler}
              />
            </div>
            <div className="search-form">
              <h4>Search users by name</h4>
              <input
                className="form-input2"
                type="text"
                name="userName"
                onChange={changeHandler}
              />
            </div>
            <div className="check-boxes">
              <form>
                <label>
                  <input
                    type="checkbox"
                    name="individuals"
                    onChange={changeHandler}
                    checked={showIndividuals}
                    value="0"
                  />{" "}
                  Show individuals only
                </label>
              </form>
              <form>
                <label>
                  <input
                    type="checkbox"
                    name="organizations"
                    onChange={changeHandler}
                    checked={showOrganizations}
                    value="1"
                  />{" "}
                  Show organizations only
                </label>
              </form>
            </div>
            <div className="user-search-btns">
              <button onClick={fetchUsers}>Search users</button>
            </div>
          </div>
          <br />
          <AllUsers listOfUsers={users} />
        </div>
      </div>
    );
  }
}
export default Users;

function AllUsers(props) {
  const users = props.listOfUsers;
  return users.map((user, index) => (
    <UserItem key={user.id} index={index} user={user} len={users.length} />
  ));
}

function UserItem(props) {
  const len = props.len;
  const user = props.user;
  const index = props.index;
  return (
    <div>
      <div className="userItem">
        <div className="flip-box-activities">
          <div className="flip-box-inner">
            <div className="flip-box-front">
              <img
                className="profile-image"
                src={user.profileImage}
                alt="_"
              />
            </div>
            <div className="flip-box-back">
              <h5 style={{ fontSize: "10px" }}>{user.id}</h5>
            </div>
          </div>
        </div>
        <div className="text-wrapper2">
          {" "}
          <h4 className="user-name-text">{user.name}</h4>
        </div>
        <Link to={`/users/${user.id}`}>
          <button className="FlexBtn">View Profile</button>
        </Link>
      </div>

      {index < len - 1 && (
        <hr
          style={{
            width: "100%",
            margin: "0",
            marginBottom: "5px",
          }}
        />
      )}
    </div>
  );
}
