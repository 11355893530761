import React, { useState, useEffect } from "react";
import Navbar from "../../components/layout/navbar";
import { faPlus, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../assets/styling/addInterest.css";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import ENV from "../../utils/env";
let image;
function EditInterest() {
  const { id } = useParams();
  let history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState("");
  const [chosenImageName, setChosenImageName] = useState("");
  const [title, setTitle] = useState("");
  const [titleDe, setGermanTitle] = useState("");
  const [icon, setIcon] = useState("");
  const inputhandler = (e) => {
    if (e.target.name === "title") {
      setTitle(e.target.value);
    } else if (e.target.name === "titleDe") {
      setGermanTitle(e.target.value);
    } else {
      image = e.target.files[0];
      setChosenImageName(image.name);
    }
  };
  useEffect(() => {
    axios
      .get(
        ENV.api + `admins/interest/${id}`,
        {
          headers: {
            Authorization: localStorage.getItem("jwtToken"),
          },
          mode: "cors",
        },
        setIsLoading(true)
      )
      .then((res) => {
        setIsLoading(false);
        setTitle(res.data.title);
        setGermanTitle(res.data.titleDe);
        setIcon(res.data.icon);
      })
      .catch((err) => {
        setErrors(err.response.data.msg);
        setIsLoading(false);
      });
  }, [id]);
  const submitAddInterest = (e) => {
    e.preventDefault();
    let data;
    if (!title) {
      setErrors("Title is required.");
      return;
    }
    if (!titleDe) {
      setErrors("German title is required.");
      return;
    }
    if (image) {
      data = new FormData();
      data.append("iconImage", image);
      data.append("title", title);
      data.append("titleDe", titleDe);
    } else {
      data = {
        title,
        titleDe
      };
    }
    axios
      .put(
        ENV.api + `admins/updateInterest/${id}`,
        data,
        {
          headers: {
            Authorization: localStorage.getItem("jwtToken"),
          },
          mode: "cors",
        },
        setIsLoading(true)
      )
      .then((res) => {
        setIsLoading(false);
        history.push("/interests");
      })
      .catch((err) => {
        setErrors(err.response.data.msg);
        setIsLoading(false);
      });
  };
  if (isLoading) {
    return <div className="loader">Loading...</div>;
  } else {
    return (
      <div>
        <Navbar />
        <div className="container">
          <div className="center">
            <div className="edit-interest-form">
              <p>Edit Interest</p>
              <h4>Current Icon</h4>
              <div className="image-review">
                <img src={icon} alt="_" />
              </div>
              <form>
                <h4>Change Icon?</h4>
                <div className="wrapper">
                  <div className="file-upload">
                    <input type="file" name="icon" onChange={inputhandler} />
                    <FontAwesomeIcon icon={faPlus} />
                  </div>
                </div>
                {chosenImageName && (
                  <h4 style={{ color: "grey" }}>* {chosenImageName}</h4>
                )}
                <div>
                  <h4 className="adjust-txt-position">
                    Change interest title?
                  </h4>
                  <input
                    className="form-input"
                    type="text"
                    name="title"
                    placeholder={title}
                    onChange={inputhandler}
                    value={title}
                  />
                </div>
                <div>
                  <h4 className="adjust-txt-position">
                    Change interest german title?
                  </h4>
                  <input
                    className="form-input"
                    type="text"
                    name="titleDe"
                    placeholder={titleDe}
                    onChange={inputhandler}
                    value={titleDe}
                  />
                </div>
                <div className="btn-left">
                  <button className="btn" onClick={submitAddInterest}>
                    Submit
                  </button>
                </div>
              </form>
              <h5 className="error-message">
                {errors && <FontAwesomeIcon icon={faTimesCircle} />}
                {" " + errors}{" "}
              </h5>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EditInterest;
