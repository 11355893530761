import React from "react";
import { Line } from "react-chartjs-2";

function LineChart(props) {
  let logins = props.logins;
  if (logins)
    logins.sort(function (a, b) {
      return a - b;
    });
  let datesMap = new Map();
  for (let i in logins) {
    let s = new Date(logins[i]).toLocaleDateString("en-US");
    s = s.slice(0, -5);
    if (datesMap.get(s)) {
      datesMap.set(s, datesMap.get(s) + 1);
    } else {
      datesMap.set(s, 1);
    }
  }
  let valuesData = [...datesMap.values()];
  let keysData = [...datesMap.keys()];
  let maxVal = Math.max(...valuesData);
  let minVal = Math.min(...valuesData);
  const data = {
    labels: keysData,
    datasets: [
      {
        data: valuesData,
        backgroundColor: "rgba(149, 207, 164,0.2)",
        borderColor: "rgba(0,128,0,8)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(0,128,,0.5)",
        hoverBorderColor: "rgba(0,128,1)",
      },
    ],
  };

  const options = {
    tooltips: {
      mode: "nearest",
    },
    maintainAspectRatio: false,
    cornerRadius: 20,
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
    scales: {
      yAxes: [
        {
          ticks: {
            min: minVal - 1,
            max: maxVal,
            stepSize: Math.ceil((maxVal - minVal) / 5),
            fontColor: "green",
            fontSize: 15,
            beginAtZero: true,
            padding: 5,
          },
          gridLines: {
            drawOnChartArea: false,
            drawBorder: true,
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            drawOnChartArea: false,
            drawBorder: true,
          },
          ticks: {
            fontColor: "green",
            fontSize: 15,
            stepSize: 1,
            beginAtZero: true,
            padding: 5,
            autoSkip: true,
            maxRotation: 30,
            minRotation: 30,
            maxTicksLimit: 10,
          },
        },
      ],
    },
  };

  return <Line data={data} options={options} />;
}

export default LineChart;
