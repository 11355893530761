import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import Modal from "react-modal";
import axios from "axios";
import {
  faUserEdit,
  faUserTimes,
  faStar,
  faTrash,
  faTimesCircle,
  faCalendarAlt,
  faEye,
  faWalking,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../assets/styling/singleUser.css";
import Navbar from "../../components/layout/navbar";
import ENV from "../../utils/env";
function SingleUser(props) {
  const { id } = useParams();
  const [user, setUser] = useState({});
  const [editButton, setEditButton] = useState(true);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [location, setLocation] = useState("");
  const [gender, setGender] = useState("");
  const [profileDescription, setprofileDescription] = useState("");
  const [age, setAge] = useState("");
  const [profileImage, setProfileImage] = useState(0);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [showReviewsToUsers, setShowReviewsToUsers] = useState(true);
  const [showReviewsToActivities, setShowReviewsToActivities] = useState(false);
  const [showReceivedReviews, setShowReceivedReviews] = useState(false);
  useEffect(() => {
    axios
      .get(
        ENV.api + `admins/user/${id}`,
        {
          headers: {
            Authorization: localStorage.getItem("jwtToken"),
          },
          mode: "cors",
        },
        setIsLoading(true)
      )
      .then((res) => {
        setUser(res.data);
        setName(res.data.name);
        setEmail(res.data.email);
        setLocation(res.data.location);
        setAge(res.data.age);
        setprofileDescription(res.data.profileDescription);
        setGender(res.data.gender);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }, [id]);

  //<button onClick={()=>{console.log(user.email)}} >Click on me</button>
  const onChangeHandler = (e) => {
    if (e.target.name === "email") {
      setEmail(e.target.value);
    }
    if (e.target.name === "name") {
      setName(e.target.value);
    }
    if (e.target.name === "location") {
      setLocation(e.target.value);
    }
    if (e.target.name === "profileDescription") {
      setprofileDescription(e.target.value);
    }
    if (e.target.name === "age") {
      setAge(e.target.value);
    }
    if (e.target.name === "gender") {
      setGender(e.target.value);
    }
  };
  const onSubmitChangesHandler = () => {
    const data = {
      name,
      email,
      gender,
      location,
      profileDescription,
      age,
      profileImage,
    };
    axios
      .put(
        ENV.api + `admins/updateProfile/${id}`,
        data,
        {
          headers: {
            Authorization: localStorage.getItem("jwtToken"),
          },
          mode: "cors",
        },
        setIsLoading(true)
      )
      .then((res) => {
        setIsLoading(false);
        window.location.reload(true);
      })
      .catch((err) => {
        setIsLoading(false);
        setErrors(err.response.data);
      });
  };
  const onDeleteUserHandler = () => {
    axios
      .delete(ENV.api + `admins/deleteAccount/${id}`, {
        headers: {
          Authorization: localStorage.getItem("jwtToken"),
        },
        mode: "cors",
      })
      .then((res) => {
        props.history.push("/users");
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  };
  const checkBoxHandler = () => {
    if (profileImage === 0) {
      setProfileImage(1);
    } else {
      setProfileImage(0);
    }
    Modal.setAppElement("#root");
  };
  if (isLoading) {
    return <div className="loader">Loading...</div>;
  }
  if (Object.keys(user).length <= 1 && !isLoading) {
    return <p>No user found with that ID</p>;
  } else {
    return (
      <div>
        <Navbar />
        <div className="container">
          <Modal
            isOpen={isOpen}
            onRequestClose={() => setIsOpen(false)}
            style={{
              overlay: { backgroundColor: "rgba(228,233,220,0.7)" },
              content: {
                top: "30%",
                left: "50%",
                right: "auto",
                bottom: "auto",
                marginRight: "-50%",
                transform: "translate(-50%, -50%)",
              },
            }}
            shouldCloseOnOverlayClick={false}
          >
            <h2 style={{ textAlign: "center" }}>Delete User?</h2>
            <p>
              Are you sure you want to delete {user.name}?{" "}
              <b style={{ color: "red" }}>This action is irreversible.</b>{" "}
            </p>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <button className="btn-no" onClick={() => setIsOpen(false)}>
                No{" "}
              </button>
              <button className="btn-delete" onClick={onDeleteUserHandler}>
                Yes{" "}
              </button>
            </div>
          </Modal>

          <div className="card-profile-organizer">
            <div>
              <img
                className="profile-image-organizer"
                src={user.profileImage}
                alt="_"
              />
            </div>
            <div className="organizer-details">
              <h3>{user.name}</h3>
              <h1>
                <FontAwesomeIcon icon={faCalendarAlt} color="grey" /> Joined{" "}
                <b>{new Date(user.joindate).toDateString()}</b>{" "}
              </h1>
              <div className="ribbon">
                {" "}
                {user.status === 0 ? "individual" : "Organization"}
              </div>
            </div>
          </div>
          <hr />
          <div className="user-active">
            <h1>
              <FontAwesomeIcon icon={faWalking} className="user-active-icon" />
              Total <b>{user.numOfLogins}</b> logins{" "}
            </h1>
            <h1> | </h1>
            <h1>
              <FontAwesomeIcon icon={faEye} className="user-active-icon" />
              Last seen on <b>
                {new Date(user.lastActive).toDateString()}
              </b>{" "}
            </h1>
          </div>
          <div className="info-form">
            <div>
              <h4>Name</h4>
              <input
                className="form-info-input"
                type="text"
                name="name"
                value={editButton ? user.name : name}
                disabled={editButton}
                onChange={onChangeHandler}
              />
            </div>
            <div>
              <h4>Email</h4>
              <input
                className="form-info-input"
                type="text"
                name="email"
                value={editButton ? user.email : email}
                disabled={editButton}
                onChange={onChangeHandler}
              />
            </div>
            <div>
              <h4>Location</h4>
              <input
                className="form-info-input"
                type="text"
                name="location"
                value={editButton ? user.location : location}
                disabled={editButton}
                onChange={onChangeHandler}
              />
            </div>

            <div>
              <h4>Description</h4>
              <input
                className="form-info-input"
                type="text"
                name="profileDescription"
                value={
                  editButton ? user.profileDescription : profileDescription
                }
                onChange={onChangeHandler}
                disabled={editButton}
              />
            </div>
            {user.status === 0 && (
              <div>
                <h4>Age</h4>
                <input
                  className="form-info-input"
                  type="text"
                  name="age"
                  value={editButton ? user.age : age}
                  onChange={onChangeHandler}
                  disabled={editButton}
                />
              </div>
            )}
            {user.status === 0 && (
              <div>
                <h4>Gender</h4>

                <select
                  className="gender"
                  name="gender"
                  value={editButton ? user.gender : gender}
                  disabled={editButton}
                  onChange={onChangeHandler}
                >
                  <option value="M">Male</option>
                  <option value="F">Female</option>
                  <option value="O">Other</option>
                </select>
              </div>
            )}
            <div className="checkBox">
              <input
                type="checkbox"
                disabled={editButton}
                className="profileImageCheckbox"
                name="profileImage"
                value={profileImage}
                onChange={checkBoxHandler}
              />
              <label htmlFor="profileImage" className="image-label-checkbox">
                {" "}
                Remove {user.name}'s image
              </label>
            </div>
          </div>
          <div className="btnDivs">
            <button
              className="btnEdit"
              onClick={() => setEditButton(!editButton)}
            >
              Edit user info{" "}
              <FontAwesomeIcon
                color="white"
                className="btn-icon"
                icon={faUserEdit}
              />
            </button>
            <button className="btnRemove" onClick={() => setIsOpen(true)}>
              {" "}
              Delete user{" "}
              <FontAwesomeIcon
                color="white"
                className="btn-icon"
                icon={faUserTimes}
              />
            </button>
          </div>
          {!editButton && (
            <div className="btn-ceneterd">
              <button className="btn2" onClick={onSubmitChangesHandler}>
                Submit
                <FontAwesomeIcon
                  icon={faCheck}
                  color="white"
                  className="btn-icon"
                />
              </button>
            </div>
          )}

          <div className="user-review">
            {errors.name && (
              <h5 className="error-message">
                <FontAwesomeIcon icon={faTimesCircle} /> {" " + errors.name}{" "}
              </h5>
            )}
            {errors.age && (
              <h5 className="error-message">
                <FontAwesomeIcon icon={faTimesCircle} />{" "}
                {" " + errors.age + " "}{" "}
              </h5>
            )}
            {errors.location && (
              <h5 className="error-message">
                <FontAwesomeIcon icon={faTimesCircle} />{" "}
                {" " + errors.location + " "}{" "}
              </h5>
            )}
            {errors.profileDescription && (
              <h5 className="error-message">
                <FontAwesomeIcon icon={faTimesCircle} />{" "}
                {" " + errors.profileDescription + " "}{" "}
              </h5>
            )}
            {errors.email && (
              <h5 className="error-message">
                {" "}
                <FontAwesomeIcon icon={faTimesCircle} />
                {" " + errors.email + " "}{" "}
              </h5>
            )}
            {errors.gender && (
              <h5 className="error-message">
                <FontAwesomeIcon icon={faTimesCircle} />{" "}
                {" " + errors.gender + " "}{" "}
              </h5>
            )}
          </div>

          {user.activitiesOrganizer && user.activitiesOrganizer.length > 0 && (
            <div className="header-text">
              <hr />
              <h4>{user.name}'s activities</h4>
            </div>
          )}
          <div className="user-review2">
            {user.activitiesOrganizer && (
              <ActivitiesOrganizer activities={user.activitiesOrganizer} />
            )}
          </div>
          {user.userReviews &&
            (user.userReviews.length > 0 ||
              user.reviewsOfOtherUsers.length > 0 ||
              user.activitiesReviews.length > 0) && (
              <div>
                <div className="header-text">
                  <hr />
                </div>
                <div className="tabs">
                  <div
                    className={
                      showReviewsToUsers ? "active-tab" : "inactive-tab"
                    }
                    onClick={() => {
                      setShowReviewsToUsers(true);
                      setShowReviewsToActivities(false);
                      setShowReceivedReviews(false);
                    }}
                  >
                    Reviews to users
                  </div>
                  <div>|</div>
                  <div
                    className={
                      showReceivedReviews ? "active-tab" : "inactive-tab"
                    }
                    onClick={() => {
                      setShowReviewsToUsers(false);
                      setShowReviewsToActivities(false);
                      setShowReceivedReviews(true);
                    }}
                  >
                    Received reviews
                  </div>
                  <div>|</div>
                  <div
                    className={
                      showReviewsToActivities ? "active-tab" : "inactive-tab"
                    }
                    onClick={() => {
                      setShowReviewsToUsers(false);
                      setShowReviewsToActivities(true);
                      setShowReceivedReviews(false);
                    }}
                  >
                    Reviews to activities
                  </div>
                </div>
                <div className="reviews-grid">
                  {user.userReviews && showReviewsToUsers && (
                    <Reviews deletebtn={true} reviews={user.userReviews} />
                  )}
                </div>
                <div className="reviews-grid">
                  {user.reviewsOfOtherUsers && showReceivedReviews && (
                    <Reviews
                      deletebtn={false}
                      reviews={user.reviewsOfOtherUsers}
                    />
                  )}
                </div>
                <div className="reviews-grid">
                  {user.activitiesReviews && showReviewsToActivities && (
                    <ReviewsActivties reviews={user.activitiesReviews} />
                  )}
                </div>
              </div>
            )}
        </div>
      </div>
    );
  }
}

function Reviews(props) {
  const reviews = props.reviews;
  const deletebtn = props.deletebtn;
  function deleteReviewHandler(user, id) {
    axios
      .delete(ENV.api + `admins/review/${user}/${id}`, {
        headers: {
          Authorization: localStorage.getItem("jwtToken"),
        },
        mode: "cors",
      })
      .then((res) => {
        window.location.reload(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  if (reviews.length === 0) {
    return (
      <div className="no-reviews-text">
        <h1>No reviews have been submitted yet</h1>
      </div>
    );
  } else {
    return reviews.map((review) => (
      <ul key={review._id} className="list-review">
        <div className="user-review-tabs">
          <Link
            style={{ textDecoration: "none" }}
            target="_blank"
            to={deletebtn ? `/users/${review.to}` : `/users/${review.from}`}
          >
            <li className="listitem-review1">
              {deletebtn ? review.toName : review.fromName}
            </li>
          </Link>
          <hr />
          <li className="listitem-review2"> {review.reviewtext}</li>
          <li className="listitem-review3">
            {" "}
            {review.rating}{" "}
            <FontAwesomeIcon color="gold" icon={faStar}></FontAwesomeIcon>{" "}
          </li>
          {deletebtn && (
            <button
              className="delete-review"
              onClick={() => deleteReviewHandler(review.from, review._id)}
            >
              Delete Review{" "}
              <FontAwesomeIcon
                size="1x"
                color="red"
                icon={faTrash}
              ></FontAwesomeIcon>
            </button>
          )}
        </div>
      </ul>
    ));
  }
}

function ReviewsActivties(props) {
  const reviews = props.reviews;
  if (reviews.length === 0) {
    return (
      <div className="no-reviews-text">
        <h1>No reviews have been submitted yet</h1>
      </div>
    );
  } else {
    return reviews.map((review) => (
      <ul key={review._id} className="list-review">
        <div className="user-review-tabs">
          <Link
            style={{ textDecoration: "none" }}
            target="_blank"
            to={`/activities/${review.to}`}
          >
            <li className="listitem-review1">{review.toTitle}</li>
          </Link>
          <hr />
          <li className="listitem-review2"> {review.reviewtext}</li>
          <li className="listitem-review3">
            {" "}
            {review.rating}{" "}
            <FontAwesomeIcon color="gold" icon={faStar}></FontAwesomeIcon>{" "}
          </li>
        </div>
      </ul>
    ));
  }
}
function ActivitiesOrganizer(props) {
  const activities = props.activities;
  const len = activities.length;
  return activities.map((activity, index) => (
    <div className="activity-organizer">
      <Link
        style={{ textDecoration: "none" }}
        to={`/activities/${activity._id}`}
      >
        <h5 className="reviewOrganizer">{activity.title}</h5>
      </Link>
      {index < len - 1 && <hr style={{ width: "100%", margin: "0" }} />}
    </div>
  ));
}
export default SingleUser;
